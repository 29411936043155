@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
}

form {
    -webkit-appearance: none;
    opacity: 1;
}

button {
    -webkit-appearance: none;
    opacity: 1;
    appearance: none;
    -moz-appearance: none;
}

input {
    -webkit-appearance: none;
    opacity: 1;
}

input[type=text], input[type=button] {
    -webkit-appearance: none;
}

button:hover {
    cursor: pointer;
}

a {
    color: black;
}

.just-button:hover {
    cursor: pointer;
}

::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif;
}

/*
S-Header
*/

#main-header-container {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background:white;
  width: 100%;
  position: fixed;
  top: 0;
  font-size: 15px;
}

#header-link-container {
  margin-right: 20px;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.header-link:hover {
  cursor: pointer;
  font-weight: bold;
}


/*
E-Header
*/


/*
S-Main-Container
*/

#page-container {
    min-width: 800;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

#main-container {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

#fill-container {
    height: 300px;
}

/*
E-Container
*/


/*
S-SearchBar
*/

#brand-bar-container {
    margin-top: 80px;
    background-color: lightyellow;
    display: flex;
    width: 478px;
    flex-direction: column;
    align-items: center;
    border: solid 1px;
    border-radius: 5px;
}

#brand-container {
    height: 100px;
    width: 390px;
    background-color: lightyellow;
}

#brand-span {
    font-size: 94px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: flex-start;
}

#form-wrapper {
    display: flex;
    flex-direction: row;
    width: 388px;
    border: solid 1px;
    border-radius: 3px;
}

#input-wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 3px;
}

#input-field {
    width: 260px;
    height: 28px;
    font-size: 16px;
    border: none;
    border-radius: 3px;
}

#input-field:focus {
    outline: none;
    background-color: white !important;
    background: transparent;
  }

#input-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 50px;
    background-color: white;
}

#keyboard-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#keyboard-button-icon {
    font-size: 15px;
}

#swedish-letters-container {
    background-color: white;
    position: absolute;
    z-index: 2;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    width: 60px;
    border-radius: 1px;
    border: lightgray solid 1px;
    border-right: 0;
    margin-top: 21px;
    margin-left: 247px;
}
  
.swedish-letter-span {
    border-right: 1px solid lightgray;
    width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
  
.swedish-letter-span:hover {
    cursor: pointer;
    background-color: #e8e8e8;
}

#shuffle-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#shuffle-button-icon {
    font-size: 15px;
}

#search-button-wrapper {
    width: 80px;
    background-color: black;
}

#search-button {
    height: 100%;
    width: 100%;
    background-color: black;
    color: white;
    border: 1px solid;
    border-color: black;
    font-size: 14px;
    text-align: center;
    -webkit-appearance: none;
    opacity: 1;
    appearance: none;
    -moz-appearance: none;
}

#search-button:hover{
    cursor: pointer;
    background-color: rgb(40, 40, 40);
}

*:focus {
    outline: 0 !important;
}
  
#welcome-description-container {
    height: 48px;
    width: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#welcome-description {
    text-align: center;
    width: 98%;
    font-size: 13px;
}

/*
E-SearchBar
*/

/*
S-Lexicon
*/
#lex-container {
    margin-top: 4px;
    width: 478px;
    height: auto;
    border: solid lightgrey 1px;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#lex-types-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
}

.lex-type-container:hover {
    cursor: pointer;
}

#lex-header-container {
    width: 96%;
    margin-top: 4px;
    margin-left: 3%;
    margin-right: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#lex-circles-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lex-circle {
    margin: 2px;
}

.lex-circle:hover {
    cursor: pointer;
}

#lex-meaning-container {
    width: 95%;
    text-align: left;
}

#lex-bojnings-container {
    margin-left: 0%;
    margin-bottom: 4px;
}

.lex-bojning {
    font-style: oblique;
    font-size: 10px;
}

.lex-bojning:hover {
    cursor: pointer;
    text-decoration: underline;
}

#lex-definition-container {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
}

#lex-definition-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 95%;
}

#lex-pin-icon {
    margin-top: 3px;
    font-size: 11px;
    margin-right: 3px;
}

#lex-synonyms-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 6px;
}

#lex-synonyms-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 95%;
}

#lex-clone-icon {
    font-size: 11px;
    margin-top: 3px;
    margin-right: 3px;
}

#lex-license-container {
    width: 95%;
    margin-top: 4px;
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

a.lex-content-owner-name {
    font-size: 8px;
    text-decoration: underline;
    margin-right: 3px;
}

#lex-license-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.lex-content-owner {
    margin-right: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
a.lex-content-owner {
    text-decoration: none;
    color: black;
}

.lex-creative-commons {
    margin-right: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
a.lex-content-owner:hover {
    text-decoration: underline;
}
  
a.lex-creative-commons {
    text-decoration: none;
    color: black;
}


/*
E-Lexicon
*/

/*
S-Player
*/

#player-container {
    margin-top: 4px;
}

/* -->
youtbe player container
*/ 

#youTube-player-container {
    background-color: black;
}


/* -->
player bar
*/

#player-bar {
    height: 43px;
    width: 478px;
    border-bottom: solid 1px lightgray;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: white;
    align-items: center;
}

#empty-player-bar-top {
    height: 15px;
    background-color: teal;
}

#player-control-button-set {
    height: 15px;
    width: 270px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.seek-button-wrapper {
    height: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#seek-backward-button-text {
    font-size: 15px;
}

#undo-icon {
    font-size: 11px;
}

#previous-video-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#previous-video-icon {
    font-size: 14px;
}

#replay-button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#replay-icon {
    font-size: 15px;
}

#next-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#next-video-icon {
    font-size: 14px;
}

#seek-forward-button-text {
    font-size: 15px;
}

#redo-icon {
    font-size: 11px;
}

#extras-player-bar {
    height: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#stats-text {
    font-size: 8px;
    margin-left: 2px;
}

#bug-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#bug-icon {
    font-size: 8px;
    margin-right: 2px;
}

/*
E-Player
*/

/*
S-Subtitle
*/

#main-subtitle-container {
    width: 480px;
    height: fit-content;
    align-items: center;
    box-sizing: border-box;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    border-bottom: solid 3px lightgrey;
}

.subtitle-empty {
    height: 10px;
}

#subtitle-wrapper {
    display: flex;
    flex: 4;
    align-items: center;
    width: 80%;
    justify-content: center;
    position: relative;
}

#subtitle-wrapper-minilex {
    display: flex;
    flex: 4;
    align-items: center;
    width: 80%;
    justify-content: center;
    position: relative;
}

#subtitle-paragraph {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#subtitle-paragraph-minilex {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.subtitle-word {
    width: fit-content;
    white-space: pre-wrap;
}

.subtitle-unlinked-word {
    width: fit-content;
    white-space: pre-wrap;
}
  
.subtitle-word:hover {
    cursor: pointer;
    text-decoration: underline;
}

/*
E-Subtitle
*/

/*
S-MiniLex
*/

.subtitle-mini-box {
    z-index: 2;
    min-width: 250px;
    position: absolute;
}

.mini-lexicon-container {
    background-color:white;
    height: auto;
    box-sizing: border-box;
    border-radius: 1px;
    border: 1px solid lightgray;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#mini-lex-types-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
}

.mini-lex-type-container:hover {
    cursor: pointer;
}

#mini-lex-header-container {
    width: 96%;
    margin-top: 4px;
    margin-left: 3%;
    margin-right: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#mini-lex-bojnings-container {
    margin-left: 0%;
    margin-bottom: 4px;
}

.mini-lex-bojning {
    font-style: oblique;
    font-size: 9px;
}

#mini-lex-circles-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#mini-lex-meaning-container {
    width: 95%;
    text-align: left;
}

#mini-lex-translation-container {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
}

#mini-lex-definition-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 95%;
}

#mini-lex-gb-flag-icon {
    margin-top: 3px;
}

#mini-lex-license-container {
    height: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/*
E-MiniLex
*/

/*
S-Footer
*/

#main-footer-container {
    background-color: lightyellow;
    width: 100vw;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#footer-upper-container {
    width: 480px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#footer-pages-container {
    width: 480px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#contact-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
}

.footer-link {
    margin-bottom: 4px;
    font-size: 13px;
}

.footer-link:hover {
    cursor: pointer;
    font-weight: bold;
}


#footer-copyright {
    width: 480px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

/*
E-Footer
*/

/*
S-AboutPage
*/

.brand-wrapper-in-other-pages {
    margin-top: 80px;
}

#about-description {
    font-size: 14px;
}

/*
E-AboutPage
*/

/*
Media queries

//iPhone 5/SE 320x568
//iPhone 6/7/8 375x667
//iPhone 6/7/8 Plus 414x736
//iPhone X 375x812
//iPad 768x1024
//iPad Pro 1024x1366
*/


@media(max-width: 500px) {
    /*
    smaller screen and iPhone Plus
    //iPhone 6/7/8 Plus 414x736

    the videe's width will become 360px 

    #page-container?
    */
    #main-header-container {
        justify-content: center;
    }

    #header-link-container {
        margin: 0 auto;
    }

    #main-container{
        width: 390px;
        margin-left: 0;
        margin-right: 0;
    }

    #brand-bar-container {
        width: 366px;
        margin-top: 40px
    }

    #brand-container {
        height: 80px;
        width: 300px;
    }

    #brand-span {
        height: 65px;
        font-size: 74px;
    }

    #form-wrapper {
        width: 300px;
    }

    #input-field {
        width: 170px;
    }

    #welcome-description-container {
        height: 40px;
        width: 300px;
    }

    #welcome-description {
        font-size: 11px;
    }

    /*
    buttons wrapper, 50
    search button 80
    */

    #lex-container {
        width: 366px;
    }

    #lex-circles-container {
        margin-right: 10px;
    }

    .lex-circle {
        margin-right: 5px;
    }

    #player-bar {
        width: 368px;
    }

    #player-control-button-set {
        width: 200px;
    }

    #main-subtitle-container {
        width: 368px;
    }

    #subtitle-wrapper-minilex {
        width: 50%;
        margin-right: 40%;
    }

    .mini-lexicon-container {
        font-size: 7px;
    }

    .mini-lex-bojning {
        font-size: 7px;
    }

    #footer-upper-container {
        width: 368px;
    }

    #footer-pages-container {
        width: 368px;
    }

    #footer-copyright {
        width: 368px;
        font-size: 12px;
    }

    .brand-wrapper-in-other-pages {
        margin-top: 40px;
    }

    #about-description {
        width: 330px;
        font-size: 12px;
    }

    #privacy-policy-container {
        width: 330px;
        font-size: 12px;
        margin-top: 20px;
    }

}

@media(max-width: 415px) {
    /*
    the videe's width will become 368px 

    //iPhone X 375x812
    //iPhone 6/7/8 375x667

    #page-container?
    */
    #main-header-container {
        justify-content: center;
    }

    #header-link-container {
        margin: 0 auto;
    }

    #main-container{
        width: 390px;
        margin-left: 0;
        margin-right: 0;
    }

    #brand-bar-container {
        width: 366px;
        margin-top: 40px
    }

    #brand-container {
        height: 80px;
        width: 300px;
    }

    #brand-span {
        height: 65px;
        font-size: 74px;
    }

    #form-wrapper {
        width: 300px;
    }

    #input-field {
        width: 170px;
    }

    #swedish-letters-container {
        margin-left: 158px;
        margin-top: 25px;
    }

    #search-button-wrapper {
        display: flex;
        background-color: black;
        flex-direction: row;
        justify-content: flex-start;
    }

    #search-button {
        font-size: 10px;
        margin-left: 2px;
        width: 60px;
        text-align: center;
        background-color: black;
        border: transparent;
        -webkit-appearance: none;
        appearance: none;
    }

    #welcome-description-container {
        height: 40px;
        width: 300px;
    }

    #welcome-description {
        font-size: 11px;
    }

    /*
    buttons wrapper, 50
    search button 80
    */

    #lex-container {
        width: 366px;
    }

    #player-bar {
        width: 368px;
    }

    #player-control-button-set {
        width: 200px;
    }

    #main-subtitle-container {
        width: 368px;
    }

    #subtitle-wrapper-minilex {
        width: 50%;
        margin-right: 40%;
    }

    .mini-lexicon-container {
        font-size: 7px;
        width: 200px;
    }

    .mini-lex-bojning {
        font-size: 7px;
    }

    #footer-upper-container {
        width: 360px;
    }

    #footer-pages-container {
        width: 368px;
    }

    #footer-copyright {
        width: 368px;
        font-size: 12px;
    }

    .brand-wrapper-in-other-pages {
        margin-top: 40px;
    }

    #about-description {
        width: 330px;
        font-size: 12px;
    }

    #privacy-policy-container {
        width: 330px;
        font-size: 12px;
        margin-top: 20px;
    }

}

@media(max-width: 370px) {
    /*
    the videe's width will become 310px 

    //iPhone 5/SE 320x568

    #page-container?
    */
    #main-header-container {
        justify-content: center;
        font-size: 12px;
    }

    #header-link-container {
        margin: 0 auto;
    }

    #main-container{
        width: 320px;
        margin-left: 0;
        margin-right: 0;
    }

    #brand-bar-container {
        width: 308px;
        margin-top: 25px;
    }

    #brand-container {
        height: 70px;
        width: 300px;
    }

    #brand-span {
        height: 55px;
        font-size: 64px;
    }

    #form-wrapper {
        width: 270px;
    }

    #input-field {
        width: 150px;
    }

    #search-button-wrapper {
        width: 70px;
        display: flex;
        background-color: black;
        flex-direction: row;
        justify-content: flex-start;
    }

    #search-button {
        font-size: 10px;
        margin-left: 3px;
        width: 40px;
        text-align: center;
        background-color: black;
        border: transparent;
        -webkit-appearance: none;
        appearance: none;
    }

    #swedish-letters-container {
        margin-left: 148px;
        margin-top: 25px;
    }

    #welcome-description-container {
        height: 35px;
        width: 270px;
    }

    #welcome-description {
        font-size: 10px;
    }

    /*
    buttons wrapper, 50
    search button 80
    */

    #lex-container {
        width: 308px;
    }

    #player-bar {
        width: 310px;
    }

    #player-control-button-set {
        width: 200px;
    }

    #main-subtitle-container {
        width: 308px;
    }

    #subtitle-wrapper-minilex {
        width: 50%;
        margin-right: 40%;
    }

    .mini-lexicon-container {
        font-size: 7px;
        width: 180px;
    }

    .mini-lex-bojning {
        font-size: 7px;
    }

    #footer-upper-container {
        width: 300px;
    }

    .footer-link {
        font-size: 12px;
    }

    #contact-container {
        font-size: 12px;
    }

    #footer-copyright {
        width: 310px;
        font-size: 10px;
    }

    .brand-wrapper-in-other-pages {
        margin-top: 25px;
    }

    #about-description {
        width: 280px;
        font-size: 12px;
    }

    #privacy-policy-container {
        width: 280px;
        font-size: 12px;
        margin-top: 20px;
    }

}